import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Summary = () => {
  return (
    <Section id="summary">
      <Container>
        <HrLine />
        <Message>Hedera provides turnkey e-learning platform and mobile apps dedicated to your organization with its own user accounts, course creation tools and offers ability to monetize courses</Message>
        <HrLine />
      </Container>
    </Section>
  );
}

export default Summary;

const HrLine = styled.hr`
  background-color: ${props => props.theme.color.accent};
  color: ${props => props.theme.color.accent};
  align: center;
  height: 2px;
  margin: 0 auto;
  width: 25%;
`;

const Message = styled.h4`
  color: ${props => props.theme.color.secondary};
  letter-spacing: 0px;
  text-align: center;
`
