import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <SectionTitle>Serverless E-learning Platform</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitleContainer>
            <FeatureIcon className="material-icons">cloud_queue</FeatureIcon>
            <FeatureTitle>Isolated Servers</FeatureTitle>
          </FeatureTitleContainer>
          <FeatureText>
            No data sharing
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitleContainer>
            <FeatureIcon className="material-icons">code_off</FeatureIcon>
            <FeatureTitle>No Coding Required</FeatureTitle>
          </FeatureTitleContainer>
          <FeatureText>
            No Programming knowledge necessary
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitleContainer>
            <FeatureIcon className="material-icons">phonelink</FeatureIcon>
            <FeatureTitle>Responsive Application</FeatureTitle>
          </FeatureTitleContainer>
          <FeatureText>
            Works on any browser
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitleContainer>
            <FeatureIcon className="material-icons">domain</FeatureIcon>
            <FeatureTitle>Custom Domains</FeatureTitle>
          </FeatureTitleContainer>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitleContainer>
            <FeatureIcon className="material-icons">supervisor_account</FeatureIcon>
            <FeatureTitle>Own User Accounts</FeatureTitle>
          </FeatureTitleContainer>
          <FeatureText>
            Role based user accounts
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitleContainer>
            <FeatureIcon className="material-icons">phone_iphone</FeatureIcon>
            <FeatureTitle>Mobile Apps</FeatureTitle>
          </FeatureTitleContainer>
          <FeatureText>
            Android / iOS
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitleContainer>
            <FeatureIcon className="material-icons">monetization_on</FeatureIcon>
            <FeatureTitle>Monetise</FeatureTitle>
          </FeatureTitleContainer>
          <FeatureText>
            Own what you earn
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitleContainer>
            <FeatureIcon className="material-icons">security</FeatureIcon>
            <FeatureTitle>Secure</FeatureTitle>
          </FeatureTitleContainer>
          <FeatureText>
            Encrypted Content
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)`
  /* transform: skewY(5deg);
  border-radius: 4px;
  background-image: linear-gradient(to top, #fefefe 0%, #fbfbfb 100%); */
`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.secondary};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  justify-items: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`

const FeatureTitle = styled.h5`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
`

const FeatureText = styled.p`
  text-align: center;
  font-size: 1rem;
  color: ${props => props.theme.color.black.lightest};
`
const FeatureIcon = styled.span`
  font-size: 5rem;
  color: ${props => props.theme.color.black.light};
  margin-right: 1rem;
`

const FeatureTitleContainer = styled.div`
  display: flex;
  align-items: center;
`
